<template>
    <div class="relative" @clickaway="closeDropdown">
      <!-- Select Input Display -->
      <div 
        class="w-full border rounded-md px-3 py-2 cursor-pointer flex items-center justify-between"
        :class="[
          isOpen ? 'border-blue-500 ring-2 ring-blue-200' : 'border-gray-300',
          disabled ? 'bg-gray-100 cursor-not-allowed' : 'bg-white',
          error ? 'border-red-500 ring-2 ring-red-200' : ''
        ]"
        @click="toggleDropdown"
      >
        <!-- Selected Value Display -->
        <span :class="!selectedOption ? 'text-gray-500' : 'text-gray-900'">
          {{ displayValue }}
        </span>
  
        <!-- Chevron Icon -->
        <svg 
          class="w-4 h-4 transition-transform duration-200"
          :class="isOpen ? 'rotate-180' : ''"
          xmlns="http://www.w3.org/2000/svg" 
          viewBox="0 0 20 20" 
          fill="currentColor"
        >
          <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
        </svg>
      </div>
  
      <!-- Dropdown -->
      <transition 
        enter-active-class="transition duration-200 ease-out"
        enter-from-class="transform scale-95 opacity-0"
        enter-to-class="transform scale-100 opacity-100"
        leave-active-class="transition duration-150 ease-in"
        leave-from-class="transform scale-100 opacity-100"
        leave-to-class="transform scale-95 opacity-0"
      >
        <div 
          v-if="isOpen" 
          class="absolute z-10 w-full mt-1 bg-white border border-gray-300 rounded-md shadow-lg max-h-60 overflow-y-auto"
        >
          <!-- Search Input -->
          <div v-if="searchable" class="sticky top-0 p-2 bg-white border-b border-gray-200">
            <input 
              type="text" 
              v-model="searchQuery"
              placeholder="Search..." 
              class="w-full px-2 py-1 text-sm border rounded focus:outline-none focus:ring-2 focus:ring-blue-200"
              @click.stop
            >
          </div>
  
          <!-- Options List -->
          <ul class="py-1">
            <li 
              v-for="option in filteredOptions" 
              :key="option[optionValue]"
              class="px-3 py-2 cursor-pointer text-gray-700 hover:bg-blue-50 transition-colors"
              :class="{ 'bg-blue-50': isSelected(option) }"
              @click="selectOption(option)"
            >
              {{ option[optionLabel] }}
            </li>
            <li 
              v-if="filteredOptions.length === 0" 
              class="px-3 py-2 text-gray-500 text-center"
            >
              No options found
            </li>
          </ul>
        </div>
      </transition>
  
      <!-- Error Message -->
      <p v-if="error" class="mt-1 text-xs text-red-500">{{ error }}</p>
    </div>
  </template>
  
  <script>
  export default {
    name: 'SelectComponent',
    props: {
      modelValue: {
        type: [String, Number, Object],
        default: null
      },
      options: {
        type: Array,
        required: true
      },
      optionLabel: {
        type: String,
        default: 'label'
      },
      optionValue: {
        type: String,
        default: 'value'
      },
      placeholder: {
        type: String,
        default: 'Select an option'
      },
      searchable: {
        type: Boolean,
        default: false
      },
      disabled: {
        type: Boolean,
        default: false
      },
      error: {
        type: String,
        default: ''
      }
    },
    data() {
      return {
        isOpen: false,
        searchQuery: ''
      }
    },
    computed: {
      displayValue() {
        if (!this.modelValue) return this.placeholder
        const option = this.options.find(opt => opt[this.optionValue] === this.modelValue)
        return option ? option[this.optionLabel] : this.placeholder
      },
      filteredOptions() {
        if (!this.searchQuery) return this.options
        const query = this.searchQuery.toLowerCase()
        return this.options.filter(option => 
          option[this.optionLabel].toLowerCase().includes(query)
        )
      },
      selectedOption() {
        return this.options.find(opt => opt[this.optionValue] === this.modelValue)
      }
    },
    methods: {
      toggleDropdown() {
        if (!this.disabled) {
          this.isOpen = !this.isOpen
        }
      },
      closeDropdown() {
        this.isOpen = false
        this.searchQuery = ''
      },
      selectOption(option) {
        this.$emit('update:modelValue', option[this.optionValue])
        this.$emit('change', option)
        this.closeDropdown()
      },
      isSelected(option) {
        return option[this.optionValue] === this.modelValue
      }
    }
  }
  </script>

<style lang="scss" scoped >
@import "../../../assets/scss/tailwinds.scss";
</style>