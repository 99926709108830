<template>
    <scalable-modal 
      :uid="uid"
      :open="open"
      @close="$emit('update:open', false)"
      @callback="handleConfirm"
      :advancedControl="true"
      validationButtonText="Supprimer"
      exitButtonText="Annuler"
      displayMode="flex"
      :style="'width: 30em; height: 22em;'"
      :break="breakModal"
    >
      <template v-slot:content>
        <p>
          {{ message }}
          <span style="color: red;">{{ warning }}</span>
        </p>
  
        <div class="col-12 pl-0">
          <div class="form-group py-3 pe-0 pe-md-2">
            <p class="mb-2">
              <strong>{{ confirmationPrompt }}</strong>
            </p>
  
            <template v-if="confirmationType === 'text'">
              <input 
                class="form-control"
                type="text"
                autocomplete="off"
                v-model="inputValue"
                :placeholder="placeholder"
              />
            </template>
  
            <template v-else-if="confirmationType === 'checkbox'">
              <label class="d-flex align-items-center">
                <input type="checkbox" v-model="checkboxChecked" />
                <span class="ms-3">
                  {{ checkboxLabel }}
                </span>
              </label>
            </template>
          </div>
        </div>
      </template>
    </scalable-modal>
  </template>
  
  <script>
  import ScalableModal from '@/components/shared/modal/ScalableModal.vue'

  export default {
    components: {
      ScalableModal,
      
    },
    props: {
      uid: { type: String, required: true },
      open: { type: Boolean, required: true },
      message: { type: String, default: "Êtes-vous sûr de vouloir supprimer cet élément?" },
      warning: { type: String, default: "Cette action est irréversible." },
      confirmationType: { type: String, default: "text", validator: (val) => ["text", "checkbox"].includes(val) },
      confirmationText: { type: String, default: "" }, // Required text for validation
      placeholder: { type: String, default: "Entrez le nom..." },
      checkboxLabel: { type: String, default: "Je confirme la suppression" },
      breakModal: {default: null}
    },
    data() {
      return {
        inputValue: "",
        checkboxChecked: false,
      };
    },
    computed: {
      confirmationPrompt() {
        return this.confirmationType === "text"
          ? "Entrez l'intitulé pour confirmation"
          : "Confirmez la suppression";
      },
      isValid() {
        if (this.confirmationType === "text") {
          return this.inputValue === this.confirmationText;
        }
        return this.checkboxChecked;
      },
    },
    methods: {
      handleConfirm() {
        if (this.isValid) {
          this.$emit("confirm");
        } else {
          this.$emit("error")
          this.$emit('update:open', false)
        }
      },
    },
  };
  </script>
  