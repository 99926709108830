<template>
  <div class="cfa-root">
    <c-f-a-nav :alert="alertString">
      <template v-slot:title>
        <div v-show="!waitingAPIResponse" class="w-100 pt-5 text-start mt-2 mb-3 d-flex justify-content-between align-items-center">
          <!-- <h1 class="cfa-section-title"><span class="cfa-primary">CAMPAGNE : </span>{{ groupDetails.detail.name }}</h1> -->
          <h1 class="cfa-section-title">
            <span class="cfa-primary">CAMPAGNE : </span>{{ campaignName }}
          </h1>
          <div v-if="status == 'created'">
          <span class="badge rounded-pill badge-danger- cfa-btn-gold p-2" style="font-size: .9em;">CAMPAGNE EN ATTENTE DE VALIDATION&nbsp;</span>
          </div>
          <div v-if="status == 'In progress'">
          <span class="badge rounded-pill badge-danger- cfa-btn-gold p-2" style="font-size: .9em;">CAMPAGNE EN COUR&nbsp;</span>
          </div>
        </div>
        <br>
      </template>
      <template v-slot:content>
        <div v-show="!waitingAPIResponse" class="cfa-page">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <div class="d-flex justify-content-between align-items-center mt-2">
                  <h5 class="mb-0 px-1">Informations sur le campagne</h5>
                  <!--<span class="badge rounded-pill badge-danger- cfa-btn-secondary p-3">CAMPAGNE EN COUR&nbsp;</span>-->
              </div>
              <div class="row mb-1 d-flex flex-wrap">
                    <div class="col-12 col-md-3 p-2">
                        <div class="bg-white d-flex m-2 py-3 gBlock">
                            <div class="p-3 gFolder-container">
                                <img width="30" src="@/assets/img/png/phishing.png" alt="dashboard state image"/>
                            </div>
                            <div>
                                <p>{{ orgName }}</p>
                                <p>Campagne de Phishing</p>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 col-md-3 p-2">
                        <div class="bg-white gBlock d-flex m-2 py-3">
                            <div class="p-3 gFolder-container">
                                <img width="30" src="@/assets/img/png/groupe.png" alt="dashboard state image"/>
                            </div>
                            <div>
                            <p>{{ groupLength }} utilisateur</p>
                                <p>dans le groupe</p>
                            </div>
                        </div>
                    </div>

                  <div class="col-12 col-md-3 p-2">
                      <div class="bg-white gBlock d-flex m-2 py-3">
                          <div class="p-3 gFolder-container">
                              <img width="30" src="@/assets/img/png/start.png" alt="dashboard state image"/>
                          </div>
                          <div>
                              <!--<p>03 Juin 2013, 08:00</p>-->
                              <p>{{ date == null ? "---" : date }}</p>
                              <p>à {{ hour }}</p>
                              <p>Date de lancement</p>
                          </div>
                      </div>
                  </div>

                  <div class="col-12 col-md-3 p-2">
                      <div class="bg-white gBlock d-flex m-2 py-3">
                          <div class="p-3 gFolder-container">
                              <img width="30" src="@/assets/img/png/time-left.png" alt="dashboard state image"/>
                          </div>
                          <div>
                              <!--<p>17 Juin 2013, 19:00</p>-->
                              <p>{{ dateEnd == null ? "---" : dateEnd }}</p>
                              <p>à {{ hourEnd }}</p>
                              <p>Date de fin</p>
                          </div>
                      </div>
                  </div>
            </div>

              <div class="row m-1" style="padding: 1.5em 0.5em; background: var(--primary-light-color-2); border-radius: 5px;">
                  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <div class="row">
                          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                              <div id="timeline_chart"></div>
                          </div>
                      </div>
                      <div class="row">
                          <div class="d-flex justify-content-around flex-md-row flex-column">
                              <div class="text-center">
                                  <h6>Email envoyés</h6>
                                  <DoughnutChart1
                                          :valueCountUp="true"
                                          :valueCountUpDuration="700"
                                          :customPercentSize="100"
                                          :classValue="'chartTextStyle2'"
                                          :width="80"
                                          :height="80"
                                          time="yes"
                                          :percent="emailsEnvoyes  || '0'"
                                          :visibleValue="true"
                                          foregroundColor="rgb(3, 57, 60)"
                                          backgroundColor="#57b99c"
                                          :strokeWidth="15"
                                  />
                              </div>
                              <div class="text-center">
                                  <h6>Email ouverts</h6>
                                  <DoughnutChart1
                                          :valueCountUp="true"
                                          :valueCountUpDuration="700"
                                          :customPercentSize="100"
                                          :classValue="'chartTextStyle2'"
                                          :width="80"
                                          :height="80"
                                          time="yes"
                                          :percent="emailsOuverts  || '0'"
                                          :visibleValue="true"
                                          foregroundColor="rgb(3, 57, 60)"
                                          backgroundColor="#f0c056"
                                          :strokeWidth="15"
                                  />
                              </div>
                              <div class="text-center">
                                  <h6>Liens clickés</h6>
                                  <DoughnutChart1
                                          :valueCountUp="true"
                                          :valueCountUpDuration="700"
                                          :customPercentSize="100"
                                          :classValue="'chartTextStyle2'"
                                          :width="80"
                                          :height="80"
                                          time="yes"
                                          :percent="liensCliques  || '0'"
                                          :visibleValue="true"
                                          foregroundColor="rgb(3, 57, 60)"
                                          backgroundColor="#f0c056"
                                          :strokeWidth="15"
                                  />
                              </div>
                              <div class="text-center">
                                  <h6>Données soumises</h6>
                                  <DoughnutChart1
                                          :valueCountUp="true"
                                          :valueCountUpDuration="700"
                                          :customPercentSize="100"
                                          :classValue="'chartTextStyle2'"
                                          :width="80"
                                          :height="80"
                                          time="yes"
                                          :percent="donnesSoumises  || '0'"
                                          :visibleValue="true"
                                          foregroundColor="rgb(3, 57, 60)"
                                          backgroundColor="#df6556"
                                          :strokeWidth="15"
                                  />
                              </div>
                              <div class="text-center">
                                  <h6>Email  signalé</h6>
                                  <DoughnutChart1
                                          :valueCountUp="true"
                                          :valueCountUpDuration="700"
                                          :customPercentSize="100"
                                          :classValue="'chartTextStyle2'"
                                          :width="80"
                                          :height="80"
                                          time="yes"
                                          :percent="emailsSignales  || '0'"
                                          :visibleValue="true"
                                          foregroundColor="rgb(3, 57, 60)"
                                          backgroundColor="#df6556"
                                          :strokeWidth="15"
                                  />
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="d-flex justify-content-end align-items-center my-3">
                  <!--<h6 class="mb-0 p-1">Dashbaord</h6>-->
                  <button class="btn cfa-btn-primary" @click="downloadReport">
                      <i class="fa fa-download"></i>
                      GENERER LE RAPPORT
                  </button>
              </div>

              <h5 class="mb-1 p-1 mt-2">Details</h5>
              <div class="row px-0 m-1 tab-body">
                
                <div class="card">
            

            <DataTable ref="dt" :value="fields" v-model:selection="selectedProducts" dataKey="id"
                :paginator="true" :rows="10" :filters="filters"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[5,10,25]"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} campaign">
                <template #header>
                    <div class="flex flex-wrap gap-2 align-items-center justify-content-between">
                        <h4 class="m-0"> Campaign Activate</h4>
                    </div>
                </template>
                
                <Column selectionMode="multiple" style="width: 3rem" :exportable="false"></Column>
                <Column field="name" header="Nom" sortable style="min-width:12rem"></Column>
                <Column field="launch_date" header="Date création" sortable style="min-width:10rem">
                  <template #body="slotProps">
                    {{ formatDate(slotProps.data.launch_date) }}
                  </template>
                </Column>
                   <Column field="end_date" header="Date de fin" sortable style="min-width:10rem">
                    <template #body="slotProps">
                      {{ formatDate(slotProps.data.end_date) }}
                    </template>
                  </Column>
                
                <Column field="template_details.name" header="Template" sortable style="min-width:12rem"></Column>
                <Column field="landing_page_details.name" header="Page" sortable style="min-width:12rem"> </Column>
                <Column field="groups" header="Group Names" sortable style="min-width:12rem">
                <template #body="slotProps">
                    <div v-if="slotProps.data.groups && slotProps.data.groups.length">
                        <span v-for="(group, index) in slotProps.data.groups" :key="group.id">
                            {{ group.name }}<span v-if="index < slotProps.data.groups.length - 1">, </span>
                        </span>
                    </div>
                    <div v-else>No groups</div>
                </template>
                </Column>

                        <Column field="smtp_details.name" header="SMTP" sortable style="min-width:12rem"> </Column>
                
                <Column :exportable="false" style="min-width:8rem,min-height:8rem" header="Actions">
                    <template #body="slotProps">
                        <!-- {slotProps.data.is_validated_by_admin ==false} ? <Button icon="fa fa-pencil" btn-action="view" outlined rounded class="ms-2 btn btn-success" @click="getCallToAction(slotProps.data)" ><i class="fa  fa-pencil"></i></Button> :<h5 style="color:cyan;">validated</h5> -->
                         <Button v-if="slotProps.data.is_validated_by_admin ==false" icon="fa fa-pencil" btn-action="view" outlined rounded class="ms-2 btn btn-success" @click="getCallToAction(slotProps.data)" ><i class="fa  fa-pencil">Edit</i></Button>
                         <Button v-else disabled icon="fa fa-pencil" btn-action="view" outlined rounded class="ms-2 btn btn-success" @click="getCallToAction(slotProps.data)" ><i class="fa  fa-pencil">Edit</i></Button>
                         <Button icon="fa fa-trash" outlined rounded severity="danger" class="ms-2 btn btn-success" @click="confirmUpdates()" :disabled="slotProps.data.is_validated_by_admin" ><i class="fa fa-check">Validate</i></Button> 
                    </template>
                </Column>
            </DataTable>
        
          </div>
              </div>

              <h5 class="mb-1 p-1 mt-2">Utilisateurs</h5>
              <div class="row px-0 m-1 tab-body">
                  <table-expend class="px-0" max-th-width="0" last-cell-width="0" :allow-extension="false" :columns="resultColumns" :data="result" :columns-action="resultToListen" classFilter="" @action="getCallToAction"></table-expend>
              </div>
            </div>
        </div>
         
        <!-- edit campaign -->
        <scalable-modal @callback="confirmUpdate" :break="exitEditModal" label="EDITER LA CAMPAGNE" @close="openUpdateModal=false" :open="openUpdateModal" :advancedControl="true" validationButtonText="Enregistrer" exitButtonText="Annuler" display-mode="flex" :style="'width: 40em; height: auto; margin-top: 2em; margin-bottom: 2em; background-color: #f0f0f0;'">
            <template v-slot:content>
                <!-- blo1 -->
                <div v-if="tempUpdateID == null" class="w-100">

                <div class="row w-100 m-inherit" style="margin-top: 2vh; margin-bottom: 2vh;">
                    <div class="form-group py-2 ">
                    <p class="mb-2"><strong>Nom</strong></p>
                    <div class="d-flex flex-row">
                        <input class="form-control" type="text" v-model="updateDataPayload.name">
                    </div>
                    </div>
                    
                </div>

                <div class="row w-100 m-inherit" style="margin-top: 2vh;">
                    <div class="form-group py-2 ">
                    <p class="mb-2"><strong>Template</strong></p>
                    <div class="d-flex flex-row">
                        <select class="form-control" v-model="newTemp">
                            <option disabled  value="">{{ updateDataPayload.template_details.name }}</option>
                            <option  :value="updateDataPayload.template_details.id">{{ updateDataPayload.template_details.name }}</option>
                            <option v-for="template in filteredTemplate" :key="template.id" :value="template.id">{{ template.name }}</option>
                        </select>
                    </div>
                    </div>
                    </div>
                    <div class="row w-100 m-inherit" style="margin-top: 2vh;">
                    <div class="form-group py-2 ">
                    <p class="mb-2"><strong>Page</strong></p>
                    <div class="d-flex flex-row">
                        <select class="form-control" v-model="newPage">
                            <option disabled  value="">{{ updateDataPayload.landing_page_details.name }}</option>
                            <option  :value="updateDataPayload.landing_page_details.id">{{ updateDataPayload.landing_page_details.name }}</option>
                            <option v-for="page in filteredLandings" :key="page.id" :value="page.id">{{ page.name }}</option>
                        </select>
                    </div>
                    </div>
                </div>
                 <div class="row w-100 m-inherit" style="margin-top: 2vh;">
                    <div class="form-group py-2 ">
                    <p class="mb-2"><strong>URL<e style="color: red">*</e></strong></p>
                    <div class="d-flex flex-row">
                        <input class="form-control" type="text" v-model="updateDataPayload.url">
                    </div>
                    </div>
                    </div> 
                <div class="row w-100 m-inherit" style="margin-top: 2vh; margin-bottom: 2vh;">
                    <div class="form-group py-2 ps-0 pe-0 pe-md-2 col-6">
                    <p class="mb-2"><strong>Date de lancement</strong></p>
                    <div class="d-flex flex-row">
                        <!-- <input class="form-control" type="datetime-local" v-model="updateDataPayload.launch_date"> -->
                        <VueDatePicker :placeholder="updateDataPayload.launch_date == null ? 'NON DEFINI' : formatDate(updateDataPayload.launch_date)" v-model="updateDataPayload.launch_date" locale="fr" cancelText="Annuler" selectText="Choisir" :title="'DEFINIR UNE DATE'"></VueDatePicker>
                    </div>
                    </div>
                    <div class="form-group py-2 col-6 px-0">
                    <p class="mb-2"><strong>Date de fin d'envoie</strong></p>
                    <div class="d-flex flex-row">
                        <!-- <input class="form-control" type="datetime-local" v-model="updateDataPayload.send_by_date"> -->
                        <VueDatePicker :placeholder="updateDataPayload.send_by_date == null ? 'NON DEFINI' : formatDate(updateDataPayload.send_by_date)" v-model="updateDataPayload.send_by_date" locale="fr" cancelText="Annuler" selectText="Choisir" :title="'DEFINIR UNE DATE'" ></VueDatePicker>
                    </div>
                    </div>
                    <div class="form-group py-2 col-6 px-0">
                    <p class="mb-2"><strong>Date de fin</strong></p>
                    <div class="d-flex flex-row">
                        <!-- <input class="form-control" type="datetime-local" v-model="updateDataPayload.send_by_date"> -->
                        <VueDatePicker :placeholder="updateDataPayload?.end_date == null ? 'NON DEFINI' : formatDate(updateDataPayload?.end_date)" v-model="updateDataPayload.end_date" locale="fr" cancelText="Annuler" selectText="Choisir" :title="'DEFINIR UNE DATE'" ></VueDatePicker>
                    </div>
                    </div>
                </div>

                
                    <div class="row w-100 m-inherit" style="margin-top: 2vh;">
                    <div class="form-group py-2">
                    <p class="mb-2"><strong>SMTP<e style="color: red">*</e></strong></p>
                    <div class="d-flex flex-row">
                        <select class="form-control" v-model="updateDataPayload.gophish_smtp_id">
                            <option selected disabled value="">select smtp</option>
                            <option v-for="page in smtp" :key="page.id" :value="page.id">{{ page.name }}</option>
                        </select>
                    </div>
                    </div>
                </div>
                <div class="row w-100 m-inherit" style="margin-top: 2vh;">
                    <div class="form-group py-2">
                    <p class="mb-2"><strong>Groupe</strong></p>
                    <div class="d-flex flex-row">
                        <select class="form-control" v-model="newGroup">
                            <option selected disabled value="">{{ updateDataPayload.groups[0].name }}</option>
                            <option v-for="group in filteredGroups" :key="group.id" :value="group.id">{{ group.name }}</option>
                        </select>
                    </div>
                    </div>
                </div>

                </div>
                <div v-else>
                <div class="cfa-page">
                    <div class="d-flex justify-content-center align-items-center" style="min-height: 40vh">
                    <div class="preloader_center d-flex flex-column">
                        <img src="../../assets/brand/cfa-favicon1.png" alt="cfa preloader">
                        <p class="pl-3 fw-bold">chargement . . .</p>
                    </div>
                    </div>
                </div>
                </div>
            </template>
        </scalable-modal>

        <div v-show="waitingAPIResponse" class="cfa-page">
            <div class="d-flex justify-content-center align-items-center" style="min-height: 70vh">
                <div class="preloader_center d-flex flex-column">
                    <img src="../../assets/brand/cfa-favicon1.png" alt="cfa preloader">
                    <p class="pl-3 fw-bold">chargement . . .</p>
                </div>
            </div>
        </div>
        <!-- Show user detail -->
        <scalable-modal @callback="confirmUpdate" :break="exitUserShowModal" label="DETAIL UTILISATEUR" :disableControl="true"
        uid="cfa-modal-org-update" @close="openShowDetails = false" :open="openShowDetails" :advancedControl="false"
        validationButtonText="Enregistrer" exitButtonText="Annuler" display-mode="flex"
        :style="'width: 40em; height: auto; margin-top: 2em; margin-bottom: 2em; '" class="user-activity-modal" >
        <template v-slot:content>
            <!-- blo1 -->
            <div class="w-full header ">
                <p>Activite de la campagne</p>
            </div>
            <div class="w-100 event-content">
                <div class="row w-100">
                    <div class="email-part py-4 my-1">
                        <span class="mb-2"><strong>Nom</strong></span>
                        <span>{{ currentUserDetail.first_name }} {{ currentUserDetail.last_name }}</span>
                    </div>
                </div>
                <div class="row w-100">
                    <div class="email-part py-4 my-1">
                        <span class="mb-2"><strong>Email</strong></span>
                        <span>{{ currentUserDetail.email }}</span>
                    </div>
                </div>
                <div class="row w-100">
                    <div class="form-group event-part">
                        <div class="event-label">
                            <div class="event-icon" :class="{'event-ok-icon': summary.launch_date}">
                                <i class="fa fa-timeline"></i>
                            </div>
                            <p>Campagne Cree le</p>
                        </div>
                        <p> {{ formatDateToLocal(summary.launch_date, "fr-FR") }} </p>
                    </div>
                </div>
                <div class="row w-100">
                    <div class="form-group event-part">
                        <div class="event-label">
                            <div class="event-icon" :class="{'event-ok-icon': currentUserDetailActions && currentUserDetailActions.email_sent[0]}" >
                                <i class="fa fa-envelope"></i>
                            </div>
                            <p>Email envoye <span v-if="currentUserDetailActions && currentUserDetailActions.email_sent[0]"> ({{ currentUserDetailActions && currentUserDetailActions.email_sent[1] }})</span> </p>
                        </div>
                        <p :class="{ 'event-ok': currentUserDetailActions && currentUserDetailActions.email_sent[0] }"> {{ currentUserDetailActions && currentUserDetailActions.email_sent[0] ? "Oui" : "Non" }} </p>
                    </div>
                </div>
                <div class="row w-100">
                    <div class="form-group event-part">
                        <div class="event-label">
                            <div class="event-icon" :class="{'event-ok-icon': currentUserDetailActions && currentUserDetailActions.email_opened[0]}">
                                <i class="fa fa-envelope-open"></i>
                            </div>
                            <p>Email Ouvert <span v-if="currentUserDetailActions && currentUserDetailActions.email_opened[0]"> ({{ currentUserDetailActions && currentUserDetailActions.email_opened[1] }})</span></p>
                        </div>
                        <p :class="{ 'event-ok': currentUserDetailActions && currentUserDetailActions.email_opened[0] }"> {{ currentUserDetailActions && currentUserDetailActions.email_opened[0] ? "Oui" : "Non" }} </p>
                    </div>
                </div>
                <div class="row w-100">
                    <div class="form-group event-part">
                        <div class="event-label">
                            <div class="event-icon" :class="{'event-ok-icon': currentUserDetailActions && currentUserDetailActions.clicked_link[0]}">
                                <i class="fa fa-link"></i>
                            </div>
                            <p>Lien Clicke <span v-if="currentUserDetailActions && currentUserDetailActions.clicked_link[0]"> ({{ currentUserDetailActions && currentUserDetailActions.clicked_link[1] }})</span></p>
                        </div>
                        <p :class="{ 'event-ok': currentUserDetailActions && currentUserDetailActions.clicked_link[0] }"> {{ currentUserDetailActions && currentUserDetailActions.clicked_link[0] ? "Oui" : "Non" }} </p>
                    </div>
                </div>
                <div class="row w-100">
                    <div class="form-group event-part">
                        <div class="event-label">
                            <div class="event-icon" :class="{'event-ok-icon': currentUserDetailActions && currentUserDetailActions.submitted_data[0]}">
                                <i class="fa fa-paper-plane"></i>
                            </div>
                            <p>Donnees Soumis <span v-if="currentUserDetailActions && currentUserDetailActions.submitted_data[0]"> ({{ currentUserDetailActions && currentUserDetailActions.submitted_data[1] }})</span></p>
                        </div>

                        <p :class="{ 'event-ok': currentUserDetailActions && currentUserDetailActions.submitted_data[0] }"> {{ currentUserDetailActions && currentUserDetailActions.submitted_data[0] ? "Oui" : "Non" }} </p>
                    </div>
                </div>
                

            </div>
            <div v-if="waitingAPIResponse">
            <div class="cfa-page">
                <div class="d-flex justify-content-center align-items-center oups" style="min-height: 40vh">
                    <div class="preloader_center d-flex flex-column">
                        <img src="../../assets/brand/cfa-favicon1.png" alt="cfa preloader">
                        <p class="pl-3 fw-bold">{{ $t('loading') }}</p>
                    </div>
                </div>
            </div>
            </div>
        </template>
        </scalable-modal>
      </template>
    </c-f-a-nav>
    <c-f-a-footer></c-f-a-footer>
  </div>
</template>

<script>
//import CFAFooter from '@/components/nav/CFAFooter.vue'
import CFANav from '@/components/nav/CFANav.vue'
import DoughnutChart1 from '@/components/shared/chart/DoughnutChart1.vue'
import TableExpend from "@/components/shared/table/TableExpend.vue";
// import Table from "@/components/shared/table/Table.vue";
import {getAPI} from "@/axios-api";
import ScalableModal from '@/components/shared/modal/ScalableModal.vue'
import VueDatePicker from '@vuepic/vue-datepicker';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
                   


// import Table from "@/components/shared/table/Table.vue";
export default {
  name: 'DashboardView',
  components: {
    //CFAFooter,
    CFANav,
    DataTable,
    Column,
    DoughnutChart1,
    // Table,
    TableExpend,
    ScalableModal,
    VueDatePicker
  },
  data () {
      return {
          waitingAPIResponse: true,
          result: [],
          selectedProducts:'',
          filters: {},
          resultColumns: ['Nom & Prénoms', 'Email', 'Position', 'Telephone', 'Pays', 'Statut', 'Actions'],
          fields: [],
          fieldsColumns: ['Nom', 'Date de démarrage', 'Date de fin', 'Template','Page', 'Groupe', 'Actions'],
          fieldsToListen: ['Actions'],
          resultToListen: ['Actions'],
          //groupDetails: null,
          groupDetails: { data: {} },
          emailsEnvoyes: 0,
          emailsOuverts: 0,
          liensCliques: 0,
          donnesSoumises: 0,
          emailsSignales: 0,
          groupId: "",
          groupLength: 0,
          groupN: "",
          groupName: "",
          exitEditModal: null,
          openUpdateModal: false,
          tempUpdateID: null,
          /*updateDataPayload: {
            gophish_landing_page_id : null,
            gophish_template_id : null,
            gophish_smtp_id : null,
            send_by_date : null,
            launch_date : null,
            url : null,
            organisation: {
              id: null,
            }
          },*/
          updateDataPayload: null,
          templateData: [],
          templateData1: [],
          newCampaignDateStart: null,
          newCampaignDateEnd: null,
          allGroups: [],
          newGroup: "",
          templateName: "",
          newTemp: "",
          allTemplates: [],
          newPage: "",
          pageName: "",
          allLandings: [],
          launchDate: null,
          launchD: null,
          startDate: null,
          startDateD: null,
          status: "",
          campaignName: "",
          orgName: "",
          date: "",
          hour: "",
          dateEnd: "",
          hourEnd: "",
          smtp:[],
          alertString: null,
          groupUsers: null,
          summary: null,
          groupResultDetails: null,
          groupResultActivityDetails: null,
          openShowDetails: false,
          exitUserShowModal: false,
          currentUserDetail: null,
          currentUserDetailActions: {
              email_sent: [false, false],
              email_opened: [false, false],
              clicked_link: [false, false], 
              submitted_data: [false, false], 
          }

      }
  },
  computed: {
    
    filteredTemplate() {
      return this.allTemplates.filter(temp => temp.name !== this.updateDataPayload.template_details.name);
    },
    filteredLandings() {
      return this.templateData1.filter(land => land.name !== this.updateDataPayload.landing_page_details.name);
    },
    filteredGroups() {
      return this.allGroups.filter(group => group.name !== this.updateDataPayload.groups[0].name);
    },
  },
  methods: {
    
    formatDate(date) {
        const formattedDate = new Date(date).toLocaleString();
        return formattedDate;
    },
    getAllTemplates(){
        getAPI.get(`phishing/templates/`)
          .then(response => {
            if(response.status == 200) {
              this.allTemplates = response.data.data;
            }
            else {
              //let msg = 'Oups ! something went wrong.'
              //this.alertString = `${msg}|alert-no|5000::`+Date.now()
            }
            setTimeout(() => {
              this.waitingAPITemplate = false
            }, 2000);
          })
          .catch(error => {
            if (error.response) {
              //this.alertString = `Aucun template disponible |alert-no|10000::`+Date.now()
            }
            else if (error.request) {
              //let msg = 'The request was made but no response was received. Please check your network.'
              //this.alertString = `${msg}|alert-no|8000::`+Date.now()
            }
            else {
              //let msg = 'Oups ! something went wrong.'
              //this.alertString = `${msg}|alert-no|5000::`+Date.now()
            }
            this.waitingAPITemplate = false
        })
    },
    getTemplate () {
      this.waitingAPITemplate = true
      getAPI.get(`phishing/templates/`)
          .then(response => {
              if(response.status == 200) {
                  this.templateData = response.data.data;
                  this.templateName = this.templateData[0].name
              }
              else {
                  //let msg = 'Oups ! something went wrong.'
                  //this.alertString = `${msg}|alert-no|5000::`+Date.now()
              }
              setTimeout(() => {
                  this.waitingAPITemplate = false
              }, 2000);
          })
          .catch(error => {
              if (error.response) {
                  //this.alertString = `Aucun template disponible |alert-no|10000::`+Date.now()
              }
              else if (error.request) {
                  //let msg = 'The request was made but no response was received. Please check your network.'
                  //this.alertString = `${msg}|alert-no|8000::`+Date.now()
              }
              else {
                  //let msg = 'Oups ! something went wrong.'
                  //this.alertString = `${msg}|alert-no|5000::`+Date.now()
              }
              this.waitingAPITemplate = false
          })
        },
        getLanding () {
            this.waitingAPITemplate = true
            getAPI.get(`phishing/landing-pages/`)
                .then(response => {
                    if(response.status == 200) {
                        this.templateData1 = response.data.data;
                        this.pageName = this.templateData1[0].name
                    }
                    else {
                        //let msg = 'Oups ! something went wrong.'
                        //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                    }
                    setTimeout(() => {
                        this.waitingAPITemplate = false
                    }, 2000);
                })
                .catch(error => {
                    if (error.response) {
                        //this.alertString = `Vous n'appartenez à aucune organisation|alert-no|10000::`+Date.now()
                    }
                    else if (error.request) {
                        //let msg = 'The request was made but no response was received. Please check your network.'
                        //this.alertString = `${msg}|alert-no|8000::`+Date.now()
                    }
                    else {
                        //let msg = 'Oups ! something went wrong.'
                        //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                    }
                    this.waitingAPITemplate = false
                })
        },
        geSmtp () {
            this.waitingAPITemplate = true
            getAPI.get(`phishing/sending-profiles/`)
                .then(response => {
                    if(response.status == 200) {
                        this.smtp = response.data.data;
                        
                    }
                    else {
                        //let msg = 'Oups ! something went wrong.'
                        //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                    }
                    setTimeout(() => {
                        this.waitingAPITemplate = false
                    }, 2000);
                })
                .catch(error => {
                    if (error.response) {
                        //this.alertString = `Vous n'appartenez à aucune organisation|alert-no|10000::`+Date.now()
                    }
                    else if (error.request) {
                        //let msg = 'The request was made but no response was received. Please check your network.'
                        //this.alertString = `${msg}|alert-no|8000::`+Date.now()
                    }
                    else {
                        //let msg = 'Oups ! something went wrong.'
                        //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                    }
                    this.waitingAPITemplate = false
                })
        },
        getLandings () {
            getAPI.get(`phishing/landing-pages/`)
                .then(response => {
                    if(response.status == 200) {
                        this.allLandings = response.data.data;
                    }
                    else {
                        //let msg = 'Oups ! something went wrong.'
                        //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                    }
                    setTimeout(() => {
                        this.waitingAPITemplate = false
                    }, 2000);
                })
                .catch(error => {
                    if (error.response) {
                        //this.alertString = `Vous n'appartenez à aucune organisation|alert-no|10000::`+Date.now()
                    }
                    else if (error.request) {
                        //let msg = 'The request was made but no response was received. Please check your network.'
                        //this.alertString = `${msg}|alert-no|8000::`+Date.now()
                    }
                    else {
                        //let msg = 'Oups ! something went wrong.'
                        //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                    }
                    this.waitingAPITemplate = false
                })
        },
    updateData (pos) {
      this.updateDataPayload = pos
      this.openUpdateModal = true
    },
    updateDatas (pos) {
      this.updateDataPayload = pos
      //this.openUpdateModal = true
      this.confirmUpdates ();
    },
    getCallToActionss () {
        
            getAPI.get(`phishing/campaign/${this.$route.params.id}/`)
            .then(response => {
              if(response.status === 200) {
                let data = response.data.data.detail
                //this.updateDatas(data);
                this.matchUpdatedData(data)
                console.log("Datta: ", data)
                

              }
              else {
                //let msg = 'Oups ! something went wrong.'
                //this.alertString = `${msg}|alert-no|5000::`+Date.now()
              }
            })
            .catch(error => {
              if (error.response) {
                //this.alertString = `${error.response.data[0]}|alert-no|10000::`+Date.now()
              }
              else if (error.request) {
                //let msg = 'The request was made but no response was received. Please check your network.'
                //this.alertString = `${msg}|alert-no|8000::`+Date.now()
              }
              else {
                //let msg = 'Oups ! something went wrong.'
                //this.alertString = `${msg}|alert-no|5000::`+Date.now()
              }
            })
        
      },
      getCallToAction (payload) {
        console.log(payload);
        if (payload.action_type == "details") {
          this.callResultAction(payload)
        } else {
          this.openUpdateModal = true
        }
        return
        //eslint-disable-next-line
            getAPI.get(`phishing/campaign/${this.$route.params.id}/`)
            .then(response => {
              if(response.status === 200) {
                let data = response.data.data.detail
                //this.updateData(data);
                console.log("Daaaaaaaaaaataaaaaaaaaaaaaa: ", data)
              }
              else {
                //let msg = 'Oups ! something went wrong.'
                //this.alertString = `${msg}|alert-no|5000::`+Date.now()
              }
            })
            .catch(error => {
              if (error.response) {
                //this.alertString = `${error.response.data[0]}|alert-no|10000::`+Date.now()
              }
              else if (error.request) {
                //let msg = 'The request was made but no response was received. Please check your network.'
                //this.alertString = `${msg}|alert-no|8000::`+Date.now()
              }
              else {
                //let msg = 'Oups ! something went wrong.'
                //this.alertString = `${msg}|alert-no|5000::`+Date.now()
              }
            })
        
      },
       getCallToActions (payload) {
        if(payload.action_type === 'edit') {
            getAPI.get(`phishing/campaign/${this.$route.params.id}/`)
            .then(response => {
              if(response.status === 200) {
                let data = response.data.data
                this.updateData(data);
                console.log("Daaaaaaaaaaataaaaaaaaaaaaaa: ", data)
              }
              else {
                //let msg = 'Oups ! something went wrong.'
                //this.alertString = `${msg}|alert-no|5000::`+Date.now()
              }
            })
            .catch(error => {
              if (error.response) {
                //this.alertString = `${error.response.data[0]}|alert-no|10000::`+Date.now()
              }
              else if (error.request) {
                //let msg = 'The request was made but no response was received. Please check your network.'
                //this.alertString = `${msg}|alert-no|8000::`+Date.now()
              }
              else {
                //let msg = 'Oups ! something went wrong.'
                //this.alertString = `${msg}|alert-no|5000::`+Date.now()
              }
            })
        }
      },
      confirmUpdates ( ){
        const group = [];
        if (!this.updateDataPayload || !this.updateDataPayload.url || this.updateDataPayload.url == "" || !this.updateDataPayload.gophish_smtp_id) {
          console.log("nope")
          this.alertString = `Veuillez specifier l'url et le SMTP|alert-no|10000::`+Date.now()
          return
        }
        group.push(this.newGroup || this.updateDataPayload.groups[0].id);
        getAPI.patch(`phishing/campaign/${this.$route.params.id}/`, {
            name: this.updateDataPayload.name,
           // page: this.newPage || this.updateDataPayload.landing_page_details.id,
           // template: this.newTemp || this.updateDataPayload.template_details.id,
            //smtp:this.newSmtp || this.updateDataPayload.smtp.id,
            groups: group,
            gophish_landing_page_id:this.updateDataPayload.gophish_landing_page_id,
            gophish_template_id:this.updateDataPayload.gophish_template_id,
            gophish_smtp_id:this.updateDataPayload.gophish_smtp_id,

            send_by_date: this.updateDataPayload.send_by_date,
            end_date: this.updateDataPayload.end_date,
            launch_date: this.updateDataPayload.launch_date,
            //send_by_date: this.newCampaignDateEnd,
            //lauch_date: this.newCampaignDateStart,
            url: this.updateDataPayload.url,
            organisation: this.updateDataPayload.organisation.id,
            //status: this.updateDataPayload.status,
            is_validated_by_admin: true
          })
            .then(response => {
              if(response.status === 200) {
                // let data = response.data.data
                // this.updateData(data);
                window.location.reload();
                this.getCampaignDetails();
                // let msg = 'Campaign validated successfully !'
                // this.alertString = `${msg}|alert-no|5000::`+Date.now()
                this.exitEditModal = true
              }
              else {
                let msg = 'Oups ! something went wrong.'
                this.alertString = `${msg}|alert-no|5000::`+Date.now()
                this.exitEditModal = true
              }
            })
            .catch(error => {
              this.exitEditModal = true
              if (error.response) {
                //this.alertString = `${error.response.data[0]}|alert-no|10000::`+Date.now()
              }
              else if (error.request) {
                //let msg = 'The request was made but no response was received. Please check your network.'
                //this.alertString = `${msg}|alert-no|8000::`+Date.now()
              }
              else {
                //let msg = 'Oups ! something went wrong.'
                //this.alertString = `${msg}|alert-no|5000::`+Date.now()
              }
            })
      },
      confirmUpdate ( ){
        const group = [];
        group.push(this.newGroup || this.updateDataPayload.groups[0].id);
        getAPI.patch(`phishing/campaign/${this.$route.params.id}/`, {
            name: this.updateDataPayload.name,
           // page: this.newPage || this.updateDataPayload.landing_page_details.id,
           // template: this.newTemp || this.updateDataPayload.template_details.id,
            //smtp:this.newSmtp || this.updateDataPayload.smtp.id,
            groups: group,
            gophish_landing_page_id:this.updateDataPayload.gophish_landing_page_id,
            gophish_template_id:this.updateDataPayload.gophish_template_id,
            gophish_smtp_id:this.updateDataPayload.gophish_smtp_id,

            send_by_date: this.updateDataPayload.send_by_date,
            end_date: this.updateDataPayload.end_date,
            launch_date: this.updateDataPayload.launch_date,
            //send_by_date: this.newCampaignDateEnd,
            //lauch_date: this.newCampaignDateStart,
            url: this.updateDataPayload.url,
            organisation: this.updateDataPayload.organisation.id,
            //status: this.updateDataPayload.status,
            is_validated_by_admin: false
          })
            .then(response => {
              if(response.status === 200) {
                // let data = response.data.data
                // this.updateData(data);
                window.location.reload();
                this.getCampaignDetails();
                let msg = 'Campaign updated successfully !'
                this.alertString = `${msg}|alert-yes|5000::`+Date.now()
                this.exitEditModal = true
              }
              else {
                //let msg = 'Oups ! something went wrong.'
                //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                this.exitEditModal = true
              }
            })
            .catch(error => {
              this.exitEditModal = true
              if (error.response) {
                //this.alertString = `${error.response.data[0]}|alert-no|10000::`+Date.now()
              }
              else if (error.request) {
                //let msg = 'The request was made but no response was received. Please check your network.'
                //this.alertString = `${msg}|alert-no|8000::`+Date.now()
              }
              else {
                //let msg = 'Oups ! something went wrong.'
                //this.alertString = `${msg}|alert-no|5000::`+Date.now()
              }
            })
      },
      matchUpdatedData (data) {
            console.log(this.updateDataPayload, data)
            this.updateDataPayload = data
            
      },
      convertDate(dateString, dateOnly=false) {
            const date = new Date(dateString);
            const formattedDate = date.toLocaleDateString();
            const formattedTime = date.toLocaleTimeString();
            if(!dateOnly) {
                return `<b>${formattedDate} à ${formattedTime}</b>`;
            }
            return `<b>${formattedDate}</b>`;
        },
      callResultAction(payload) {
        if (this.groupResultDetails.length <= 0 || this.groupDetails.status == "created" || this.groupDetails.status == "cancel") {
            this.$swal({
                title: 'Campagne',
                text: `Votre campagne est n'a pas encore demarre`,
                type: 'warning',
                showCancelButton: false,
                confirmButtonText: "Fermer"
            });
            return
        }
        //eslint-disable-next-line
        this.currentUserDetail = this.groupResultDetails.filter(userDetail => userDetail.id == payload.id)[0]
        console.log(this.currentUserDetail)
        // ["email_sent", "email_opened", "link_clicked"]
        this.getCurrentUserDetailStatus()
        console.log(this.currentUserDetail)
        this.openShowDetails = true
      },
      getResult () {
        this.waitingAPIResponse = true
          getAPI.get(`phishing/campaign/${this.$route.params.id}/`)
              .then(response => {
                  if(response.status == 200) {
                      this.groupDetails = response.data.data.summary;

                      if (response.data.data.detail.statistic) {
                            let statistic = response.data.data.detail.statistic
                            this.summary = {
                                stats: {
                                    total: statistic.length,
                                    sent: this.getStatsCount(statistic, "email_sent" ),
                                    opened: this.getStatsCount(statistic, "opened" ),
                                    clicked: this.getStatsCount(statistic, "clicked" ),
                                    submitted_data: this.getStatsCount(statistic, "submitted_data" ),
                                    email_reported: this.getStatsCount(statistic, "Email Reported" ),
                                    
                                },
                                launch_date: response.data.data.detail.launch_date
                            }
                        }
                        console.log(this.summary)
                        if(this.summary != "") {
                            this.emailsEnvoyes = this.summary.stats.sent;
                            this.emailsOuverts = this.summary.stats.opened;
                            this.liensCliques = this.summary.stats.clicked;
                            this.donnesSoumises = this.summary.stats.submitted_data;
                            this.emailsSignales = this.summary.stats.email_reported;
                        }
                    
                  }
                  else {
                      //let msg = 'Oups ! something went wrong.'
                      //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                  }
                  setTimeout(() => {
                      this.waitingAPIResponse = false
                  }, 2000);
              })
              .catch(error => {
                  if (error.response) {
                      //this.alertString = `Vous n'appartenez à aucune organisation|alert-no|10000::`+Date.now()
                  }
                  else if (error.request) {
                      //let msg = 'The request was made but no response was received. Please check your network.'
                      //this.alertString = `${msg}|alert-no|8000::`+Date.now()
                  }
                  else {
                      //let msg = 'Oups ! something went wrong.'
                      //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                  }
                  this.waitingAPIResponse = false
              })
            
          setTimeout(() => {
              // this.waitingAPIResponse = false
          }, 2000)
      },
    
      getAllGroups() {
        getAPI.get(`groupes/`)
          .then(response => {
            if(response.status == 200) {
              this.allGroups = response.data.data
              console.log("reponse: ", this.allGroups)
            }
            else {
                //let msg = 'Oups ! something went wrong.'
                //this.alertString = `${msg}|alert-no|5000::`+Date.now()
            }
            setTimeout(() => {
                this.waitingAPIResponse = false
            }, 2000);
          })
          .catch(error => {
            if (error.response) {
                //this.alertString = `Vous n'appartenez à aucune organisation|alert-no|10000::`+Date.now()
            }
            else if (error.request) {
                //let msg = 'The request was made but no response was received. Please check your network.'
                //this.alertString = `${msg}|alert-no|8000::`+Date.now()
            }
            else {
                //let msg = 'Oups ! something went wrong.'
                //this.alertString = `${msg}|alert-no|5000::`+Date.now()
            }
            this.waitingAPIResponse = false
        })
      },
      splitGroup(group) {
        const parties = group.split("_");
        const gpName = parties[0];
        return gpName;
      },
      formatDateForCampaign() {
        const dateHeure = this.launchD.split(" ");
        return dateHeure;
      },
      formatDateForCampaign2() {
        const dateHeure = this.startDateD.split(" ");
        return dateHeure;
      },
      getCampaignDetails() {
        this.waitingAPIResponse = true;
        getAPI.get(`phishing/campaign/${this.$route.params.id}`)
            .then(response => {
              console.log(response)
                if (response.status === 200) {
                  if (response.data.data.detail.statistic) {
                    let detail = response.data.data.detail
                    let statistic = response.data.data.detail.statistic
                    this.summary = {
                        stats: {
                            total: statistic.length,
                            sent: detail.sent_phishing,
                            opened: detail.opened_phishing,
                            clicked: detail.clicked_phishing,
                            submitted_data: detail.submitted_dat_phishing,
                            email_reported: detail.email_roported_phishing,
                            //email_reported: this.getStatsCount(statistic, "Email Reported" ),
                            
                        },
                        launch_date: response.data.data.detail.launch_date
                    }
                  }
                  console.log(this.summary)
                  if(this.summary) {
                      this.emailsEnvoyes = this.summary.stats.sent;
                      this.emailsOuverts = this.summary.stats.opened;
                      this.liensCliques = this.summary.stats.clicked;
                      this.donnesSoumises = this.summary.stats.submitted_data;
                      this.emailsSignales = this.summary.stats.email_reported;
                  }

                    let row = response.data.data.detail;
                    this.fields.push(row);
                    console.log(this.fields);
                    
                    this.groupDetails = response.data.data.detail;
                    
                    // this.status = this.groupDetails.status;
                    this.campaignName = this.groupDetails.name;
                    this.groupDetailsData = response.data.data.detail;
                    this.orgName = this.groupDetailsData.name;
                    this.groupId = this.groupDetailsData.groups[0].id;
                    const gpName = this.groupDetailsData.groups[0].name;
                    console.log("Usersgroup: ", gpName);
                    
                    this.launchD = this.formatDate(row.launch_date, true);
                    this.startDateD = this.formatDate(row.end_date, true);
                    this.date = this.formatDateForCampaign()[0];
                    this.hour = this.formatDateForCampaign()[1];
                    this.dateEnd = this.formatDateForCampaign2()[0];
                    this.hourEnd = this.formatDateForCampaign2()[1];
                    this.getGroupUsers(row.groups[0].id)
                    
                    console.log('******', this.fields);
                } else {
                    this.alertString = 'Oups ! something went wrong.|alert-no|5000::' + Date.now();
                }
                setTimeout(() => {
                    this.waitingAPIResponse = false;
                }, 2000);
            })
            .catch(error => {
                console.log(error);
                this.waitingAPIResponse = false;
            });
      },
      
      getGroupUsers (groupId) {
          this.waitingAPIResponse = true
          getAPI.get(`groupes/${groupId}`)
              .then(response => {
                console.log(response)
                  if(response.status == 200) {
                      this.groupUsers = response.data;
                      this.userDetails = this.groupUsers.user_detail

                      this.groupResultDetails = this.groupDetailsData.statistic.slice(-(this.userDetails.length))
                      console.log(this.groupResultDetails)
                      this.groupResultActivityDetails = this.processDetailsResults(this.groupDetailsData.statistic) 
                      console.log(this.groupResultActivityDetails)

                      this.groupN = this.groupUsers.name
                      console.log("Group name: ", this.groupN)
                      //this.groupName = this.splitGroup(this.groupN)
                      this.groupLength = this.userDetails.length
                      this.result = [];
                      /*for(let i = 0; i < this.userDetails.length; i++){
                          let row = this.userDetails[i];
                          this.result.push({
                              'Nom & Prénoms':`${row.lastname + " " + row.firstname}`,
                              'Email': `${row.email}`,
                              'Position': `${row.role.name == "manager" ? "MANAGER" : "MEMBRE" || "NON DEFINI"}`,
                              'Telephone': `${row.phone}`,
                              'Pays': `${row.country}`,
                              'Statut': `${row.status || "- - -"}`,
                          });
                      }*/
                      for (let i = 0; i < this.userDetails.length; i++) {
                          let row = this.userDetails[i];
                          let rowResult = null
                          let rowActivityResult = null
                          if (this.groupResultDetails.length > 0) {
                              rowResult = this.groupResultDetails.find(resultDetail => resultDetail.email == row.email)
                              rowActivityResult = this.groupResultActivityDetails[rowResult.email]
                          }
                          console.log(rowResult, rowActivityResult)
                          console.log(row)
                          let userStatus = "-"
                          if (rowResult) {
                            userStatus = "En attente"
                              console.log(rowResult)
                              //let actions = this.getStatsActions(rowResult.actions)
                              let actions = null
                              console.log(actions)
                              let actionStatus = "-"
                              if (actions && actions.length > 0) {
                                  //actionStatus  = actions[0].status ? actions[0].status : actions[0].message ? actions[0].message : "-"
                                  actionStatus  = actions.find(action => action.status).status
                              }
                              
                              if (rowResult.email_sent_phishing || actionStatus == "Email Sent") {
                                    userStatus = "Email Envoye"
                                }  
                                
                                if (rowResult.opened_phishing || actionStatus == "Email Opened") {
                                    userStatus = "Email Ouvert"
                                } 
                                if (rowResult.clicked_phishing || actionStatus == "Clicked Link") {
                                    userStatus = "Lien clicke"
                                }  
                                if (rowResult.submitted_data_phishing || actionStatus == "Submitted Data") {
                                    userStatus = "Donnees soumis"
                                }
                          }
                          this.result.push({
                              'Nom & Prénoms': `<div>${row.lastname + " " + row.firstname}</div>`,
                              'Email': `<div>${row.email}</div>`,
                              'Position': `<div>${row.role.name == "manager" ? "MANAGER" : "MEMBRE" || "NON DEFINI"}</div>`,
                              'Telephone': `<div>${row.phone ? row.phone : "-" }</div>`,
                              'Pays': `<div>${row.country ? row.country : "-" }</div>`,
                              //'Statut': `<div>${row.status || "-"}</div>`,
                              'Statut': `<div><span class="" style="display: inline-flex; align-items: center; border-radius: 0.375rem; background-color: #f0fdf4; padding: 0.25rem 0.5rem; font-size: 0.75rem; font-weight: 500; color: #166534; box-shadow: inset 0 0 0 1px rgba(22, 101, 52, 0.2);">${userStatus}</span></div>`,
                              'Actions': `<div><button title="SHOW DETAIL" btn-id="${rowResult ?rowResult.id : row.id}" btn-action="details" class="ms-2 btn cfa-btn-primary btn-action cfa-btn-light"><i class="fa fa-clipboard">&nbsp;</i></button></div>`
                          });
                      }
                      console.log(this.result, "here result")
                  }
                  else {
                      //let msg = 'Oups ! something went wrong.'
                      //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                  }
                  setTimeout(() => {
                      this.waitingAPIResponse = false
                  }, 2000);
              })
              .catch(error => {
                  if (error.response) {
                      //this.alertString = `Vous n'appartenez à aucune organisation|alert-no|10000::`+Date.now()
                  }
                  else if (error.request) {
                      //let msg = 'The request was made but no response was received. Please check your network.'
                      //this.alertString = `${msg}|alert-no|8000::`+Date.now()
                  }
                  else {
                      //let msg = 'Oups ! something went wrong.'
                      //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                  }
                  this.waitingAPIResponse = false
              })
      },
      processDetailsResults(data) {
        // Step 1: Remove exact duplicates by converting to a Map with JSON string as key
        const uniqueItems = Array.from(new Map(data.map(item => [JSON.stringify(item), item])).values());

        // Step 2: Group by email and sort each group by created_at
        const groupedByEmail = uniqueItems.reduce((groups, item) => {
            const email = item.email;
            if (!groups[email]) {
                groups[email] = [];
            }
            groups[email].push(item);
            return groups;
        }, {});

        // Step 3: Process each group
        for (const email in groupedByEmail) {
            // Step 3a: Remove duplicate statuses within the group
            const seenStatuses = new Set();
            groupedByEmail[email] = groupedByEmail[email].filter(item => {
                if (seenStatuses.has(item.status)) {
                    return false; // Duplicate status
                }
                seenStatuses.add(item.status);
                return true;
            });

            // Step 3b: Sort the group by created_at
            groupedByEmail[email].sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
        }

        return groupedByEmail;
    },
    getStatsCount(stats, status="") {
          let count = 0
          for (let i = 0; i < stats.length; i++) {
              let stat = stats[i]
              if (status != "" && stat[status]) {
                  count++
              }
          }
          
          return count
    },
    getStatsActions(statsAction) {
        if (!statsAction) return null
      let events = [];
      for (let i = 0; i < statsAction.length; i++) {
          let statAction = statsAction[i].replace(/'/g, '"'); // Replace single quotes with double quotes

          // Handle truncated strings by ensuring they end properly
          if (!statAction.endsWith('}')) {
              console.log(`Skipping malformed entry: ${statAction}`);
              statAction = this.fixMalformedEntry(statAction);
          }

          // Convert Python datetime.datetime to ISO date string
          statAction = statAction.replace(
              /datetime\.datetime\((\d+), (\d+), (\d+), (\d+), (\d+),? (\d+)?\)/g,
              (_, year, month, day, hour, minute, second = '0') =>
                  `"${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}T${hour.padStart(2, '0')}:${minute.padStart(2, '0')}:${second.padStart(2, '0')}Z"`
          );

          try {
              let action = JSON.parse(statAction); // Parse as JSON
              events.push(action); // Add the parsed object to the events array
          } catch (error) {
              console.log(`Error parsing entry: ${statAction}`);
          }
      }
      return events;
  },
    fixMalformedEntry(entry) {
        // Try closing the entry by adding a missing closing brace
        if (!entry.endsWith('}')) {
            entry += '"}';
        }

        // Check for truncated fields and attempt to reconstruct them
        /*const truncatedFieldMatch = entry.match(/"[^"]*$/); // Look for an open string
        if (truncatedFieldMatch) {
            entry = entry.replace(truncatedFieldMatch[0], '");'); // Close the string properly
        }*/

        return entry;
    },
    getCurrentUserDetailStatus() {
        this.currentUserDetailActions = {
            email_sent: [false, false],
            email_opened: [false, false],
            clicked_link: [false, false], 
            submitted_data: [false, false], 
        }
        console.log(this.currentUserDetail)
        if (this.currentUserDetail) {
            let statsAction = this.getStatsActions(this.currentUserDetail?.actions)
                console.log(statsAction)
                
            if (this.currentUserDetail.email_sent_phishing || this.currentUserDetail.opened_phishing || this.currentUserDetail.clicked_phishing  || this.currentUserDetail.submitted_data_phishing ) {
                const now = new Date()
                if (this.currentUserDetail.email_sent_phishing) {
                    this.currentUserDetailActions.email_sent = [true, this.formatDateToLocal(this.currentUserDetail.email_sent_phishing_date || now, "fr-FR", true)]
                }
                if (this.currentUserDetail.opened_phishing) {
                    this.currentUserDetailActions.email_opened = [true, this.formatDateToLocal(this.currentUserDetail.opened_phishing_date || now, "fr-FR", true)]
                    if (this.currentUserDetail.email_sent_phishing) {
                        this.currentUserDetailActions.email_sent = [true, this.formatDateToLocal(this.currentUserDetail.email_sent_phishing_date || now, "fr-FR", true)]
                    }
                }
                if (this.currentUserDetail.clicked_phishing) {
                    this.currentUserDetailActions.clicked_link = [true, this.formatDateToLocal(this.currentUserDetail.clicked_phishing_date || now, "fr-FR", true)]
                    if (this.currentUserDetail.email_sent_phishing) {
                        this.currentUserDetailActions.email_sent = [true, this.formatDateToLocal(this.currentUserDetail.email_sent_phishing_date || now, "fr-FR", true)]
                    }
                    if (this.currentUserDetail.opened_phishing) {
                        this.currentUserDetailActions.email_opened = [true, this.formatDateToLocal(this.currentUserDetail.opened_phishing_date || now, "fr-FR", true)]
                    } else {
                        this.currentUserDetailActions.email_opened = [true, this.formatDateToLocal(this.currentUserDetail.clicked_phishing_date || now, "fr-FR", true)]
                    }
                    
                }
                if (this.currentUserDetail.submitted_data_phishing) {
                    this.currentUserDetailActions.submitted_data = [true, this.formatDateToLocal(this.currentUserDetail.submitted_data_phishing_date || now, "fr-FR", true)]
                    if (this.currentUserDetail.email_sent_phishing) {
                        this.currentUserDetailActions.email_sent = [true, this.formatDateToLocal(this.currentUserDetail.email_sent_phishing_date || now, "fr-FR", true)]
                    }
                    if (this.currentUserDetail.opened_phishing) {
                        this.currentUserDetailActions.email_opened = [true, this.formatDateToLocal(this.currentUserDetail.opened_phishing_date || now, "fr-FR", true)]
                    } else {
                        this.currentUserDetailActions.email_opened = [true, this.formatDateToLocal(this.currentUserDetail.submitted_data_phishing_date || now, "fr-FR", true)]
                    }
                    if (this.currentUserDetail.clicked_phishing) {
                        this.currentUserDetailActions.clicked_link = [true, this.formatDateToLocal(this.currentUserDetail.clicked_phishing_date || now, "fr-FR", true)]
                    }
                }
            } else if (statsAction) {
                const actionStatus = statsAction.find(stat => stat.status)
                const actionsMessage = statsAction.filter(stat => stat.message)
                console.log(actionStatus, actionsMessage)
                if (actionStatus.status == "Email Sent" ) {
                    this.currentUserDetailActions.email_sent = [true, this.formatDateToLocal(actionStatus.timestamp, "fr-FR", true)]
                }
                if (actionStatus.status == "Email Opened") {
                    this.currentUserDetailActions.email_opened = [true, this.formatDateToLocal(actionStatus.timestamp, "fr-FR", true)]
                    let emailSent = actionsMessage.find(action => action.message == "Email Sent")
                    if (emailSent) {
                        this.currentUserDetailActions.email_sent = [true, this.formatDateToLocal(emailSent.time, "fr-FR", true)]
                    }
                }
                if (actionStatus.status == "Clicked Link") {
                    this.currentUserDetailActions.clicked_link = [true, this.formatDateToLocal(actionStatus.timestamp, "fr-FR", true)]
                    let emailSent = actionsMessage.find(action => action.message == "Email Sent")
                    if (emailSent) {
                        this.currentUserDetailActions.email_sent = [true, this.formatDateToLocal(emailSent.time, "fr-FR", true)]
                    }
                    let emailOpened = actionsMessage.find(action => action.message == "Email Opened")
                    if (emailOpened) {
                        this.currentUserDetailActions.email_opened = [true, this.formatDateToLocal(emailOpened.time, "fr-FR", true)]
                    } else {
                        this.currentUserDetailActions.email_opened = [true, this.formatDateToLocal(actionStatus.timestamp, "fr-FR", true)]
                    }
                }
                if (actionStatus.status == "Submitted Data") {
                    this.currentUserDetailActions.submitted_data = [true, this.formatDateToLocal(actionStatus.timestamp, "fr-FR", true)]
                    let emailSent = actionsMessage.find(action => action.message == "Email Sent")
                    if (emailSent) {
                        this.currentUserDetailActions.email_sent = [true, this.formatDateToLocal(emailSent.time, "fr-FR", true)]
                    }
                    let emailOpened = actionsMessage.find(action => action.message == "Email Opened")
                    if (emailOpened) {
                        this.currentUserDetailActions.email_opened = [true, this.formatDateToLocal(emailOpened.time, "fr-FR", true)]
                    } else {
                        this.currentUserDetailActions.email_opened = [true, this.formatDateToLocal(actionStatus.timestamp, "fr-FR", true)]
                    }
                    let clickedLink = actionsMessage.find(action => action.message == "Clicked Link")
                    if (clickedLink) {
                        this.currentUserDetailActions.clicked_link = [true, this.formatDateToLocal(clickedLink.time, "fr-FR", true)]
                    }
                }
                
            }
            
        }
    },
    formatDateToLocal(dateTime, locale = 'default', includeTime = false) {
        const date = new Date(dateTime);

        const options = {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
        };

        if (includeTime) {
            options.hour = '2-digit';
            options.minute = '2-digit';
            options.second = '2-digit'; // Optional: Include seconds if needed
            options.hour12 = false; // Set to true for 12-hour format
        }

        return date.toLocaleString(locale, options);
    },
    downloadReport() {
      //let now = new Date()
      //let endDate = new Date(this.groupDetailsData.send_by_date)
      getAPI.get(`phishing/report-admin/${this.$route.params.id}/`, { responseType: 'arraybuffer' }).then( response=> {
          console.log(response)
          if (response.status == 200) {
              if (response.data) {
                  
                  const blob = new Blob([response.data], { type: 'application/pdf' });
                  const url = window.URL.createObjectURL(blob);
                  const link = document.createElement('a');
                  link.href = url;
                  link.setAttribute('download', `${this.campaignName}_rapport.pdf`);
                  document.body.appendChild(link);
                  link.click();
                  document.body.removeChild(link);
                  window.URL.revokeObjectURL(url);
              }
          }
      })
      .catch(error => {
          console.log(error)
      })
      /*if (now > endDate) {
      } else {
          //alert("Campaign not complete")
          this.$swal({
              title: 'Rapport de campagne',
              text: `Votre campagne est toujours en cours. Veuillez patientez jusqu'au ${this.formatDateToLocal(this.groupDetailsData.send_by_date)}`,
              type: 'warning',
              showCancelButton: false,
              confirmButtonText: "Fermer"
          });
      }*/
  },
  },
  mounted() {
    //this.getResult();
    this.getCampaignDetails();
    //this.getGroupUsers();
    this.getTemplate();
    this.getLanding();
    this.getAllGroups();
    this.getAllTemplates();
    this.geSmtp ();
    this.getCallToActionss();
    //this.getLandings();
    
  }
}
</script>

<style lang="scss"  scoped>
  
/*@import "../../assets/scss/tailwinds.scss";*/

.cfa-page{}
.cfa-block{
  background-color: #fff;
  border-radius: 0.5em;
  padding: .5em 1em;
  box-shadow: -1px 1px 29px 20px rgba(222,234,250,0.87);
  -webkit-box-shadow: -1px 1px 29px 20px rgba(222,234,250,0.87);
  -moz-box-shadow: -1px 1px 29px 20px rgba(222,234,250,0.87);
}
.gFolder-container{
    background-color: var(--primary-light-color-2);
    border-radius: 0.5em;
    height: 4.3em;
    width: 4.6em;
    margin-right: 1em;
    display: flex;
    align-items: center;
    justify-content: center;
}
.gBlock{
    align-items: center;
    justify-content: start;
    border-radius: 0.6em;
    padding-left: 2em;
    padding-right: 2em;
    height: 8em !important;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
}
.gBlock div:last-of-type p{
    margin: 0;
}
.gBlock div:last-of-type p:first-of-type{
    font-weight: bold;
    font-size: 1.4em;
}

.user-activity-modal {
    & .header {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        width: 100%;
        p {
            /*@extend .sub-title-2;*/
        }
    }
    & .event-content {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 15px;
    }
    & .email-part {
        display: flex;
        justify-content: space-between;
    }
    & .event-part {
        display: flex;
        justify-content: space-between;
        align-items: center;
        & .event-label {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 10px;
        }
        & .event-ok {
            //color: $green;
            color: green;
        }
        & .event-icon {
            width: 40px;
            height: 40px;
            padding: 7px;
            border-radius: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color:darkgray;
        }
        & .event-ok-icon {
            //background-color: $accent-color;
            background-color: rgb(12, 188, 198);
        }
    }
}
</style>
