
<template>
    <div class="cfa-root">
      <c-f-a-nav :alert="alertString" contentClass="position-relative">
        <template v-slot:title>
          <div class="w-100 pt-5 text-start mt-2 mb-3">
            <h1 class="cfa-section-title">TÂCHES EN ARRIÈRE-PLAN</h1>
          </div>
        </template>
        <template v-slot:content>
          <div class="cfa-page">
        <div style=" height: 100vh; margin: 0; padding: 0; overflow: hidden;">
    <iframe id="dashboardFrame" :src="celeryDashboardUrl" width="100%" height="100%" style="border: none;"></iframe>
</div>



          </div>
        </template>
      </c-f-a-nav>
      <c-f-a-footer></c-f-a-footer>

      <!-- create -->
      <scalable-modal @callback="addNewPlan" :break="exitAddPlanModal" display-mode="flex" label="CRÉER UNE TÂCHE" uid="cfa-modal-add-plan" @close="newPlan=false" :open="newPlan" :advancedControl="true" validationButtonText="Créer" exitButtonText="Annuler" :style="'width: 50em; height: auto; margin-top: 5em; margin-bottom: 2em; background-color: #f0f0f0;'">
        <template v-slot:content>
          <div class="row w-100 mt-3">
            <div class="form-group py-3 pe-0 ">
              <p class="mb-2"><strong>NOM</strong></p>
              <input class="form-control" type="text"  v-model="name">
            </div>
          </div>
        </template>
      </scalable-modal>

      <!-- delete -->
      <scalable-modal @callback="confirmDeletion" :break="exitRoleModalDel" :notifyTaskDone="callbackPayload" :uid="'general-deletion-modal'" @close="openDeletionBox=false" :open="openDeletionBox" :advancedControl="true" validationButtonText="Supprimer" exitButtonText="Annuler" displayMode="flex" :style="'width: 30em; height: 22em;'">
        <template v-slot:content>
          <p>Etes vous sûr de vouloir supprimer cette tâche? <span style="color: red;">Cette Action est irreversible</span></p>
          <div class="col-12 pl-0">
            <div class="form-group py-3 pe-0 d-flex align-items-center">
              <input id="deleteIt" type="checkbox" v-model="plan_deletion_check">
              <label class="ms-2" for="deleteIt" ><strong>Je sais ce que je fais </strong></label>
            </div>
          </div>
        </template>
      </scalable-modal>

      <!-- update -->
      <scalable-modal @callback="confirmUpdate" :break="exitOrgUpdateModal" display-mode="flex" label="METTRE À JOUR UNE TÂCHE" uid="cfa-modal-plan-update" @close="openUpdatePerm=false" :open="openUpdatePerm" :advancedControl="true" validationButtonText="Enregistrer" exitButtonText="Annuler" :style="'width: 50em; height: auto; margin-top: 2em; margin-bottom: 2em; background-color: #f0f0f0;'">
        <template v-slot:content>
          <div v-show="waitingAPIResponse2" class="cfa-page">
            <div class="d-flex justify-content-center align-items-center" style="min-height: 70vh">
              <div class="preloader_center d-flex flex-column">
                <img src="../../assets/img/gif/rocket.gif" alt="cfa preloader">
                <p class="pl-3">loading . . .</p>
              </div>
            </div>
          </div>
          <div v-show="!waitingAPIResponse2" class=" w-100">
            <div class="row w-100 mt-3">
              <div class="form-group py-3 pe-0 ">
                <p class="mb-2"><strong>NOM</strong></p>
                <input class="form-control" type="text"  v-model="selectedPlan.name">
              </div>
            </div>
          </div>
        </template>
      </scalable-modal>
    </div>
  </template>

  <script>
  import CFAFooter from '@/components/nav/CFAFooter.vue'
  import CFANav from '@/components/nav/CFANav.vue'
  import ScalableModal from '@/components/shared/modal/ScalableModal.vue'
  import {getAPI} from "@/axios-api";
  
  
  export default {
    name: 'BackgroundTaskView',
    components: {
      CFAFooter,
      CFANav,
      ScalableModal,
    },
    data () {
      return {
        alertString: null,
        celeryDashboardUrl: process.env.VUE_APP_CELERY_DASHBOARD_URL,
        newPlan: false,
        plans: [],
        activityColumns: ['n°', 'Nom',  'Created At',  'Autres'],
        activityToListen: ['Autres'],
  
        openDeletionBox: null,
        tempDeleteID: null,
        tempDeleteName: '',
  
        waitingAPIResponse: true,
        waitingAPIResponse2: true,
        selectedPlan: null,
  
        exitAddPlanModal: null,
        name: '',
        callbackPayload: null,
        exitRoleModalDel: null,
        plan_deletion_check: null,
        openUpdatePerm: false,
        exitOrgUpdateModal: null,
        tempUpdateRoleId: null,

        // Pagination data
        currentPage: 1,
        itemsPerPage: 10,
      }
    },
    computed: {
      paginatedPlans() {
        const start = (this.currentPage - 1) * this.itemsPerPage;
        return this.plans.slice(start, start + this.itemsPerPage);
      }
    },
    methods: {
       adjustIframeHeight() {
        const iframe = document.getElementById("dashboardFrame");
        iframe.style.height = window.innerHeight + "px";
    },
    
   
      getPlans () {
        this.waitingAPIResponse = true
        getAPI.get(`proxy/flower/api/tasks`)
            .then(response => {
              if(response.status == 200) {
                let tasks = response.data.tasks;
                this.plans = []
                for(let i=0; i<tasks.length; i++) {
                  let task = tasks[i]
                  

                  this.plans.push({'n°': (i+1),'Nom': task.name,  'Created At': task.created_at, 'state':task.state,   name: task.id})
                }
              }
              else {
                let msg = 'Oups ! something went wrong.'
                this.alertString = `${msg}|alert-no|5000::`+Date.now()
              }
              setTimeout(() => {
                this.waitingAPIResponse = false
              }, 2000);
            })
            .catch(error => {
              if (error.response) {
                this.alertString = `${error.response.data.message}|alert-no|10000::`+Date.now()
              }
              else if (error.request) {
                let msg = 'The request was made but no response was received. Please check your network.'
                this.alertString = `${msg}|alert-no|8000::`+Date.now()
              }
              else {
                let msg = 'Oups ! something went wrong.'
                this.alertString = `${msg}|alert-no|5000::`+Date.now()
              }
              this.waitingAPIResponse = false
            })
      },
      getCallToAction1 (payload) {
        if(payload.action_type == 'delete') {
          this.openDeletionBox = true;
          this.tempDeleteID = payload.id;
          this.tempDeleteName = payload.name;
        }
        if(payload.action_type == 'view') {
          this.updateData(payload.id);
        }
      },
      updateData (id) {
        this.tempUpdateRoleId = id
        this.waitingAPIResponse2 = true
        this.openUpdatePerm = true
        getAPI.get(`api/proxy/flower/api/tasks/${id}/`)
            .then(response => {
              if(response.status == 200) {
                this.selectedPlan = response.data;
              }
              else {
                let msg = 'Oups ! something went wrong.'
                this.alertString = `${msg}|alert-no|5000::`+Date.now()
              }
              setTimeout(() => {
                this.waitingAPIResponse2 = false
              }, 2000);
            })
            .catch(error => {
              if (error.response) {
                this.alertString = `${error.response.data.message}|alert-no|10000::`+Date.now()
              }
              else if (error.request) {
                let msg = 'The request was made but no response was received. Please check your network.'
                this.alertString = `${msg}|alert-no|8000::`+Date.now()
              }
              else {
                let msg = 'Oups ! something went wrong.'
                this.alertString = `${msg}|alert-no|5000::`+Date.now()
              }
              this.waitingAPIResponse2 = false
            })
      },
      addNewPlan () {
        getAPI.post('api/proxy/flower/api/tasks', {
          name: this.name,
        })
        .then(response => {
          if(response.status == 201) {
            this.getPlans()
            let msg = 'Tâche ajoutée avec succès!'
            this.alertString = `${msg}|alert-yes|5000::`+Date.now()
          }
          else {
            let msg = 'Oups ! something went wrong.'
            this.alertString = `${msg}|alert-no|5000::`+Date.now()
          }
          this.exitAddPlanModal = Date.now()
        })
        .catch(error => {
          if (error.response) {
            let firstError = Object.values(error.response.data)[0][0]
            this.alertString = `${firstError}|alert-no|10000::`+Date.now()
          }
          else if (error.request) {
            let msg = 'The request was made but no response was received. Please check your network.'
            this.alertString = `${msg}|alert-no|8000::`+Date.now()
          }
          else {
            let msg = 'Oups ! something went wrong.'
            this.alertString = `${msg}|alert-no|5000::`+Date.now()
          }
          this.exitAddPlanModal = Date.now()
        })
      },
      confirmDeletion () {
        if(!this.plan_deletion_check) {
          this.callbackPayload = 'Veuillez confirmer cette action.' + '::-1::' + Date.now();
        }
        else{
          getAPI.delete(`api/proxy/flower/api/tasks/${this.tempDeleteID}`)
            .then(response => {
              if(response.status == 204) {
                this.callbackPayload = 'Tâche supprimée avec succès' + '::' + Date.now();
                setTimeout(() => {
                  this.getPlans()
                }, 1500)
              }
              else {
                let msg = 'Oups ! something went wrong.'
                this.alertString = `${msg}|alert-no|5000::`+Date.now()
                this.exitRoleModalDel = Date.now()
              }
            })
            .catch(() => {
              this.callbackPayload = 'Oups ! something went wrong.' + '::-1::' + Date.now();
            })
        }
      },
      confirmUpdate () {
        getAPI.patch(`api/proxy/flower/api/tasks/${this.tempUpdateRoleId}/`,{ name:this.selectedPlan.name}).then(response => {
          if(response.status == 200) {
            let msg = 'Tâche mise à jour avec succès!'
            this.alertString = `${msg}|alert-yes|5000::`+Date.now()
            this.exitOrgUpdateModal = Date.now()
            this.getPlans()
          }
          else {
            let msg = 'Oups ! something went wrong.'
            this.alertString = `${msg}|alert-no|5000::`+Date.now()
          }
          this.exitOrgUpdateModal = Date.now()
        })
            .catch(error => {
              if (error.response) {
                this.alertString = `Cette tâche est déjà en cours d'utilisation!|alert-no|10000::`+Date.now()
              }
              else if (error.request) {
                let msg = 'The request was made but no response was received. Please check your network.'
                this.alertString = `${msg}|alert-no|8000::`+Date.now()
              }
              else {
                let msg = 'Oups ! something went wrong.'
                this.alertString = `${msg}|alert-no|5000::`+Date.now()
              }
              this.exitOrgUpdateModal = Date.now()
            })
      },
      nextPage() {
        if (this.currentPage * this.itemsPerPage < this.plans.length) {
          this.currentPage++;
        }
      },
      prevPage() {
        if (this.currentPage > 1) {
          this.currentPage--;
        }
      },
    },
    mounted() {
      this.getPlans()
       window.addEventListener("load", this.adjustIframeHeight);
    window.addEventListener("resize", this.adjustIframeHeight);
    }
  }
  </script>

  <style scoped>
  .cfa-welcome{
    background-color: #fff;
    border-radius: 0.5em;
    padding: .5em 1em;
      box-shadow: -1px 1px 29px 20px rgba(222,234,250,0.87);
    -webkit-box-shadow: -1px 1px 29px 20px rgba(222,234,250,0.87);
    -moz-box-shadow: -1px 1px 29px 20px rgba(222,234,250,0.87);
  }
  .cfa-certif-state-line:hover{
    box-shadow: -1px 1px 29px 20px rgba(222,234,250,0.87);
    -webkit-box-shadow: -1px 1px 29px 20px rgba(222,234,250,0.87);
    -moz-box-shadow: -1px 1px 29px 20px rgba(232, 236, 241, 0.87);
  }
  .cfa-progress-status{
    min-width: 7em;
  }
  .item {
    cursor: pointer;
    border: 1px solid #ccc;
    padding: 0.5em;
    margin: 0.5em;
    border-radius: 0.25em;
  }
  .timeline-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Align items to the left */
    position: relative;
  }

  .task-list {
    list-style-type: none;
    padding: 0;
    width: 100%;
  }

  .task-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 1em;
    margin-bottom: 0.5em;
    position: relative;
  }

  .timeline-item {
    display: flex;
    align-items: center;
    position: relative;
    padding-left: 30px; /* Space for the timeline marker */
  }

  .timeline-marker {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 6px;
    background-color: #007bff; /* Marker color */
    border-radius: 3px;
  }

  .timeline-content {
    background: #f8f9fa; /* Background for the content */
    /* border: 1px solid #ccc; */
    /* border-radius: 5px; */
    padding: 10px;
    
  }

  .pagination-controls {
    margin-top: 1em;
    display: flex;
    justify-content: space-between;
  }

  .pagination-button {
    background-color: #007bff; /* Bootstrap primary color */
    color: white;
    border: none;
    padding: 0.5em 1.5em;
    border-radius: 0.5em;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.2s;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }

  .pagination-button:disabled {
    background-color: #ccc; /* Grey color for disabled state */
    cursor: not-allowed;
  }

  .pagination-button:hover:not(:disabled) {
    background-color: #0056b3; /* Darker shade on hover */
    transform: translateY(-2px); /* Lift effect */
  }

  .pagination-button:active:not(:disabled) {
    transform: translateY(1px); /* Press effect */
  }

  .card-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* Create three equal columns */
    gap: 20px; /* Space between cards */
    padding: 20px; /* Padding around the container */
  }

  .card {
    background: #f8f9fa;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 15px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }

  .status {
    margin-top: 10px; /* Space above the status */
    padding: 5px; /* Padding for the status box */
    color: white; /* Text color */
    border-radius: 3px; /* Rounded corners */
    text-align: center; /* Center the text */
  }
  </style>